import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
	Anchor,
	Box,
	Center,
	Flex,
	Image,
	SimpleGrid,
	Skeleton,
	Text,
	TextInput,
	Title,
	Tooltip,
	UnstyledButton,
} from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useDisclosure, useElementSize } from '@mantine/hooks';
import { DateInput } from '@mantine/dates';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { useQuery } from '@tanstack/react-query';
import { fileToBase64, getFileSrc } from '@/utils/utilities';
import { PassengersAmountRadioGroup } from '@/components/partials/passengersAmountRadioGroup/PassengersAmountRadioGroup';
import {
	UserVoucherForm,
	UserVoucherFormFile,
	UserVoucherStep,
	addEnabledStep,
	changePassengersAmount,
	removeEnabledStep,
	setUserVoucherStep,
	updateVoucher,
} from '@/store/slices/userVoucherSlice';
import PartnerApi from '@/api/PartnerApi';
import PhoneIcon from '@/assets/icons/phone.svg?react';
import MailIcon from '@/assets/icons/mail-02.svg?react';
import UploadCloudIcon from '@/assets/icons/upload-cloud-02.svg?react';
import FileXIcon from '@/assets/icons/file-x-03.svg?react';
import FileCheckIcon from '@/assets/icons/file-check-03.svg?react';
import HelpIcon from '@/assets/icons/help-circle.svg?react';
import PlusIcon from '@/assets/icons/plus.svg?react';
import MinusIcon from '@/assets/icons/minus.svg?react';
import classNames from 'classnames';
import styles from './UserVoucherVoucher.module.scss';

const skeletons = new Array(10).fill(0).map((_, idx) => (
	<UnstyledButton
		key={idx}
		className={classNames(styles.company, styles.skeleton)}
		p="md"
	>
		<Skeleton height={50} circle mb="lg" />
		<Skeleton height={20} />
	</UnstyledButton>
));

export const UserVoucherVoucher: React.FC = () => {
	const dispatch = useTypedDispatch();

	const options = useTypedSelector((state) => state.userVoucher.options);

	const voucher = useTypedSelector((state) => state.userVoucher.voucher);
	const handleChange = (partial: Partial<UserVoucherForm>) => {
		dispatch(updateVoucher(partial));
	};

	const handleChangePassengersAmount = (value: number) => {
		dispatch(changePassengersAmount(value));
	};

	const [showAll, showAllControls] = useDisclosure();

	useLayoutEffect(() => {
		dispatch(setUserVoucherStep(UserVoucherStep.VOUCHER));
	}, []);

	const partnersQuery = useQuery({
		queryKey: [PartnerApi.queryKey],
		queryFn: PartnerApi.getAll,
		staleTime: Infinity,
	});

	const [isCodeError, setIsCodeError] = useState(false);
	useEffect(() => {
		setIsCodeError(false);
		handleChange({ voucherCode: '' });
	}, [voucher.partner]);

	const validateVoucherCode = () => {
		if (!voucher.partner?.validationSchema) {
			setIsCodeError(false);
			return;
		}

		const regex = new RegExp(voucher.partner.validationSchema);
		setIsCodeError(!regex.test(voucher.voucherCode));
	};

	useEffect(() => {
		const isNextEnabled = options.enabledSteps.includes(
				UserVoucherStep.PASSANGERS
			),
			shouldNextBeEnabled =
				!isCodeError &&
				!!voucher.partner &&
				!!voucher.voucherCode &&
				!!voucher.image &&
				!!voucher.expirationDate;

		if (isNextEnabled && !shouldNextBeEnabled)
			dispatch(removeEnabledStep(UserVoucherStep.PASSANGERS));

		if (!isNextEnabled && shouldNextBeEnabled)
			dispatch(addEnabledStep(UserVoucherStep.PASSANGERS));
	}, [
		voucher.partner,
		voucher.voucherCode,
		voucher.image,
		voucher.expirationDate,
	]);

	const { ref: partnersBoxRef, width } = useElementSize();
	const [showAmount, setShowAmount] = useState(11);

	useLayoutEffect(() => {
		let amount = 3;

		if (width > 580) amount = 5;
		if (width > 770) amount = 7;
		if (width > 970) amount = 9;
		if (width > 1180) amount = 11;
		setShowAmount(amount);
	}, [width]);

	const viewShowMoreBtn =
		!!partnersQuery.data &&
		partnersQuery.data.length > showAmount + 1 &&
		!showAll;

	return (
		<Box>
			<Title order={2} c="blue.7" mb="xl">
				Firma w której został zakupiony voucher
			</Title>
			<Box className={styles.companies} mb="xl" ref={partnersBoxRef}>
				{partnersQuery.data
					? partnersQuery.data
							.slice(0, showAll ? partnersQuery.data.length : showAmount)
							.map((partner) => {
								const isSelected = voucher.partner?.id === partner.id;

								return (
									<UnstyledButton
										key={partner.id}
										className={classNames(styles.company, {
											[styles.selected]: isSelected,
										})}
										onClick={() => handleChange({ partner })}
									>
										<Image
											src={getFileSrc(partner.image.path)}
											mah={80}
											maw={100}
										/>
										<Text
											fw={500}
											p="sm"
											ta="center"
											bg={isSelected ? 'white' : 'dark.0'}
										>
											{partner.name}
										</Text>
									</UnstyledButton>
								);
							})
					: skeletons}
				{viewShowMoreBtn && (
					<UnstyledButton
						className={classNames(styles.company)}
						bg="blue.0"
						onClick={showAllControls.open}
					>
						<Center className={styles.decorContainer}>
							<Box className={styles.decor}>
								<PlusIcon />
							</Box>
						</Center>
						<Text fw={500} p="sm" ta="center" bg="white">
							Pokaż wszystkie
						</Text>
					</UnstyledButton>
				)}
				{showAll && (
					<UnstyledButton
						className={classNames(styles.company)}
						bg="blue.0"
						onClick={showAllControls.close}
					>
						<Center className={styles.decorContainer}>
							<Box className={styles.decor}>
								<MinusIcon />
							</Box>
						</Center>
						<Text fw={500} p="sm" ta="center" bg="white">
							Pokaż mniej
						</Text>
					</UnstyledButton>
				)}
			</Box>
			{!!voucher.partner && (
				<SimpleGrid
					cols={{
						base: 0,
						md: 2,
					}}
					className={styles.info}
					px="lg"
					mb="xl"
				>
					<Box py="lg">
						<Title order={2} c="blue.7" mb="sm">
							Gdzie szukać kodu vouchera?
						</Title>
						<Text fw={500} mb="md" c="dark.5">
							Twój voucher różni się od wskazanego na zdjęciach poglądowych? Nie
							możesz znaleźć właściwego kodu? Napisz lub zadzwoń do nas. Chętnie
							pomożemy.{' '}
							{!!voucher.partner.instruction && (
								<Anchor
									href={getFileSrc(voucher.partner.instruction.path)}
									download={voucher.partner.instruction.name}
									target="_blank"
								>
									Zobacz instrukcję.
								</Anchor>
							)}
						</Text>

						<Flex
							gap="md"
							direction={{
								base: 'column',
								md: 'row',
							}}
						>
							<Anchor td="none" href="tel:600310310">
								<Flex gap="sm" align="center">
									<PhoneIcon className="svg-danger" />
									<Text c="dark.5" fw={500}>
										600 310 310
									</Text>
								</Flex>
							</Anchor>
							<Anchor td="none" href="mailto:realizacje@wyprawabalonem.pl">
								<Flex gap="sm" align="center">
									<MailIcon className="svg-danger" />
									<Text c="dark.5" fw={500}>
										realizacje@wyprawabalonem.pl
									</Text>
								</Flex>
							</Anchor>
						</Flex>
					</Box>
					{!!voucher.partner.instructionImage && (
						<img src={getFileSrc(voucher.partner.instructionImage.path)} />
					)}
				</SimpleGrid>
			)}
			<TextInput
				size="lg"
				label="Kod vouchera"
				placeholder="Podaj kod zgodny ze wzorem partnera"
				mb="lg"
				required
				disabled={!voucher.partner}
				maw={450}
				rightSection={
					<Tooltip label="Lorem ipsum dolores">
						<UnstyledButton h={16}>
							<HelpIcon
								className="svg-gray-modern-400"
								width={16}
								height={16}
							/>
						</UnstyledButton>
					</Tooltip>
				}
				value={voucher.voucherCode}
				onChange={({ target }) => handleChange({ voucherCode: target.value })}
				onBlur={validateVoucherCode}
				error={
					isCodeError &&
					'Schemat kodu nie jest właściwy dla wybranego partnera.'
				}
			/>
			<label className="label">
				Załącz zdjęcie vouchera{' '}
				<Tooltip
					w={350}
					label="Pamiętaj aby przesłane zdjęcie było wyraźne, a wszystkie kody i numery, liczba osób, data ważności oraz nazwa vouchera były czytelne."
				>
					<UnstyledButton h={16}>
						<HelpIcon className="svg-gray-modern-400" width={16} height={16} />
					</UnstyledButton>
				</Tooltip>
			</label>
			<Dropzone
				onDrop={(files) => {
					const payload: UserVoucherFormFile = {
						data: '',
						name: files[0].name,
						type: files[0].type,
					};

					fileToBase64(files[0]).then((b64) => {
						payload.data = b64;
						handleChange({
							image: payload,
						});
					});
				}}
				maxSize={5 * 1024 * 1024}
				accept={[...IMAGE_MIME_TYPE]}
				mih={148}
				maxFiles={1}
				mb="xl"
			>
				<Dropzone.Accept>
					<Box className="dropzone-decor accept">
						<FileCheckIcon />
					</Box>
					<Text mb="xs" fw={600} c="dark.6">
						<Anchor
							href="/"
							fw={600}
							onClick={(e) => e.preventDefault()}
							c="green.6"
						>
							Upuść plik
						</Anchor>{' '}
						aby dodać do zamówienia!
					</Text>
					<Text size="sm" c="dark.6">
						SVG, PNG, JPG or GIF (max. 5MB)
					</Text>
				</Dropzone.Accept>
				<Dropzone.Reject>
					<Box className="dropzone-decor reject">
						<FileXIcon />
					</Box>
					<Text mb="xs" fw={600} c="red.5">
						Nieobsługiwany format pliku lub za duży rozmiar!
					</Text>
					<Text size="sm" c="dark.6">
						SVG, PNG, JPG or GIF (max. 5MB)
					</Text>
				</Dropzone.Reject>
				<Dropzone.Idle>
					<Box className="dropzone-decor idle">
						<UploadCloudIcon />
					</Box>
					<Text mb="xs" c="dark.6">
						<Anchor href="/" fw={600} onClick={(e) => e.preventDefault()}>
							Kliknij aby dodać
						</Anchor>{' '}
						lub przeciągnij plik
					</Text>
					<Text size="sm" c="dark.6">
						SVG, PNG, JPG or GIF (max. 5MB)
					</Text>
					{!!voucher.image && (
						<Text size="sm" c="dark.6" className="fade-in" fw={600}>
							{voucher.image.name}
						</Text>
					)}
				</Dropzone.Idle>
			</Dropzone>
			<DateInput
				minDate={new Date()}
				label="Data ważności"
				mb="lg"
				maw={450}
				rightSection={
					<Tooltip label="Lorem ipsum dolores">
						<UnstyledButton h={16}>
							<HelpIcon
								className="svg-gray-modern-400"
								width={16}
								height={16}
							/>
						</UnstyledButton>
					</Tooltip>
				}
				value={voucher.expirationDate ? new Date(voucher.expirationDate) : null}
				onChange={(value) => {
					if (value) handleChange({ expirationDate: value.getTime() });
				}}
				getMonthControlProps={(date) => ({
					selected: date.getMonth() === new Date().getMonth(),
				})}
				getYearControlProps={(date) => ({
					selected: date.getFullYear() === new Date().getFullYear(),
				})}
			/>
			<PassengersAmountRadioGroup
				passengersAmount={voucher.passengersAmount}
				handleChangePassengersAmount={handleChangePassengersAmount}
			/>
		</Box>
	);
};
