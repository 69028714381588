import React, { useLayoutEffect, useState } from 'react';
import {
	Box,
	Divider,
	Title,
	Text,
	Flex,
	UnstyledButton,
	Checkbox,
} from '@mantine/core';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import {
	setUserVoucherStep,
	updateOptions,
	updateVoucher,
	UserVoucherStep,
} from '@/store/slices/userVoucherSlice';
import { LocationPills } from '@/components/partials/locationPills/LocationPills';
import { LocationMapPicker } from '../../../../../partials/locationMapPicker/LocationMapPicker';
import { Location } from '@/api/LocationApi';
import ArrowDownCircleIcon from '@/assets/icons/arrow-circle-down.svg?react';
import FileIcon from '@/assets/icons/file-06.svg?react';
import InfoIcon from '@/assets/icons/info-hexagon.svg?react';
import BuildingIcon from '@/assets/icons/building-08.svg?react';
import APP_CONFIG from '@/configs/appConfig';

export const UserVoucherLocations: React.FC = () => {
	const dispatch = useTypedDispatch();

	const { locations } = useTypedSelector((state) => state.userVoucher.voucher);
	const { termsAccepted, newsletterAccepted } = useTypedSelector(
		(state) => state.userVoucher.options
	);

	useLayoutEffect(() => {
		dispatch(setUserVoucherStep(UserVoucherStep.LOCATION));
	}, []);

	const [selectedLocation, setSelectedLocation] = useState<Location | null>(
		null
	);

	const handleLocationSelect = (location: typeof selectedLocation) => {
			setSelectedLocation(location);
		},
		handleRemoveLocation = (location: Location) => () => {
			dispatch(
				updateVoucher({
					locations: locations.filter((loc) => loc.id !== location.id),
				})
			);
		},
		handleAddLocation = (location: Location) => () => {
			dispatch(
				updateVoucher({
					locations: [...locations, location],
				})
			);
			setSelectedLocation(null);
		};

	return (
		<Box>
			<Title order={2} c="blue.7" mb="xl">
				Lokalizacja lotu
			</Title>
			<LocationPills
				locations={locations}
				handleRemove={handleRemoveLocation}
			/>
			<LocationMapPicker
				locations={locations}
				isDisabled={locations.length >= APP_CONFIG.MAX_LOCATIONS_LENGTH}
				selectedLocation={selectedLocation}
				handleLocationSelect={handleLocationSelect}
				handleAddLocation={handleAddLocation}
				handleRemoveLocation={handleRemoveLocation}
			/>
			<Divider mb="xl" />
			<Title order={2} c="blue.7" mb="xl">
				Prosimy o zapozanie się z poniższymi dokumentami
			</Title>
			<Flex gap="xl" mb="xl" wrap="wrap">
				<UnstyledButton className="button-gray-with-icon">
					<FileIcon className="svg-danger" />
					<Text size="lg" fw={500}>
						Regulamin
					</Text>
					<Box className="button-gray-with-icon__decor">
						<ArrowDownCircleIcon />
					</Box>
				</UnstyledButton>
				<UnstyledButton className="button-gray-with-icon">
					<InfoIcon className="svg-danger" />
					<Text size="lg" fw={500}>
						Przeciwskazania dla lotu balonem
					</Text>
					<Box className="button-gray-with-icon__decor">
						<ArrowDownCircleIcon />
					</Box>
				</UnstyledButton>
				<UnstyledButton className="button-gray-with-icon">
					<BuildingIcon className="svg-danger" />
					<Text size="lg" fw={500}>
						RODO
					</Text>
					<Box className="button-gray-with-icon__decor">
						<ArrowDownCircleIcon />
					</Box>
				</UnstyledButton>
			</Flex>
			<Checkbox
				checked={termsAccepted}
				label="Zapoznałem/am się i akceptuję warunki realizacji lotu balonem oraz regulamin Balloon Expedition."
				mb="md"
				onChange={({ target }) => {
					dispatch(updateOptions({ termsAccepted: target.checked }));
				}}
			/>
			<Checkbox
				checked={newsletterAccepted}
				label="Newsletter"
				mb="xl"
				onChange={({ target }) => {
					dispatch(updateOptions({ newsletterAccepted: target.checked }));
				}}
			/>
		</Box>
	);
};
