import React, { useState } from 'react';
import { Flex, NumberInput, Radio, RadioGroupProps, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import APP_CONFIG from '@/configs/appConfig';
import UserSingleIcon from '@/assets/icons/user-01.svg?react';
import PlusIcon from '@/assets/icons/plus.svg?react';
import styles from './PassengersAmountRadioGroup.module.scss';

interface Props {
	passengersAmount: number;
	handleChangePassengersAmount: (value: number) => void;
}

export const PassengersAmountRadioGroup: React.FC<Props> = ({
	passengersAmount,
	handleChangePassengersAmount,
}) => {
	const [value, setValue] = useState(getInitialValue(passengersAmount));
	const handleChange: RadioGroupProps['onChange'] = (value) => {
		setValue(value);
		handleChangePassengersAmount(value === 'more' ? 4 : Number(value));
	};

	const isMobile = useMediaQuery(`(max-width: ${APP_CONFIG.BREAKPOINTS.MD})`);

	return (
		<Radio.Group
			mb="lg"
			name="count"
			label="Liczba pasażerów"
			value={value}
			onChange={handleChange}
		>
			<Flex gap="md" align="center" wrap="wrap">
				<Flex gap={40} wrap="wrap" mih={40} align="center">
					<Radio
						label={
							<Flex gap={12} align="center">
								<UserSingleIcon />
								<Text size="lg" fw={500}>
									1 osoba
								</Text>
							</Flex>
						}
						value="1"
					/>
					<Radio
						label={
							<Flex gap={12} align="center">
								<Flex>
									<UserSingleIcon />
									<UserSingleIcon />
								</Flex>
								<Text size="lg" fw={500}>
									2 osoby
								</Text>
							</Flex>
						}
						value="2"
					/>
					<Radio
						label={
							<Flex gap={12} align="center">
								<Flex>
									<UserSingleIcon />
									<UserSingleIcon />
									<UserSingleIcon />
								</Flex>
								<Text size="lg" fw={500}>
									3 osoby
								</Text>
							</Flex>
						}
						value="3"
					/>
					<Radio
						label={
							<Flex gap={12} align="center">
								{!isMobile && (
									<Flex>
										<Flex pos="relative">
											<UserSingleIcon />
											<UserSingleIcon />
											<UserSingleIcon className={styles.multiPassengerDecor} />
										</Flex>
										<PlusIcon />
									</Flex>
								)}
								<Text size="lg" fw={500}>
									Więcej osób
								</Text>
								{value === 'more' && (
									<NumberInput
										className="fade-in"
										min={4}
										max={APP_CONFIG.MAX_PASSENGERS_AMOUNT}
										step={1}
										maw={isMobile ? 125 : undefined}
										value={passengersAmount}
										onChange={(value) => {
											handleChangePassengersAmount(Number(value));
										}}
										clampBehavior="blur"
									></NumberInput>
								)}
							</Flex>
						}
						value="more"
					/>
				</Flex>
			</Flex>
		</Radio.Group>
	);
};

function getInitialValue(amount: number) {
	if (amount <= 3) return amount.toString();
	return 'more';
}
