import { HttpStatusCode } from 'axios';
import { DeepKeys } from '@tanstack/react-table';
import { BaseEntity } from '@/api/EntityApi';
import { ErrorCode } from '@/configs/errorCode';

export interface StringSignature<TData = any> {
	[key: string]: TData;
}

export type SortOrder = 'asc' | 'desc';

export interface Sort<TData extends BaseEntity = any> {
	key: DeepKeys<TData> | null;
	order: SortOrder;
}

export interface ApiQueryOptions<TData extends BaseEntity = any> {
	page: number;
	sizePerPage: number;
	search: string;
	searchFields: DeepKeys<TData>[];
	sort: Sort<TData>;
}

export interface ApiPaginatedResponse<TData extends {} = {}> {
	currentPage: number;
	totalPages: number;
	itemsPerPage: number;
	totalItems: number;
	data: TData[];
}

export interface ApiFormattedResponseData<TData extends {} = {}> {
	data: TData[];
	totalPages: number;
}

export interface Attachment {
	id: UUID;
	path: string;
	name: string;
	size: number;
	mimetype: string;
}

export type UUID = ReturnType<typeof crypto.randomUUID>;

export type ApiErrorMessage = ErrorCode | string | (ErrorCode | string)[];

export interface ApiError {
	error: string;
	message: ApiErrorMessage;
	status: HttpStatusCode;
}

export interface AppError {
	status: HttpStatusCode;
	stack: (ErrorCode | string)[];
	message: string;
}

export enum Gender {
	MALE,
	FEMALE,
	OTHER,
}
