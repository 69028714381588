import { User } from '@/types/user';
import { UUID } from '@/types/types';
import { QueryKey } from '@/configs/queryKeys';
import httpClient from './httpClient';
import EntityApi from './EntityApi';

class UserApi extends EntityApi<User> {
	constructor() {
		super(QueryKey.users);
		this.changePassword = this.changePassword.bind(this);
		this.changeEmail = this.changeEmail.bind(this);
		this.confirmEmailChange = this.confirmEmailChange.bind(this);
	}

	changePassword = async ({
		userId,
		currentPassword,
		newPassword,
	}: {
		userId: UUID;
		currentPassword: string;
		newPassword: string;
	}) => {
		const response = await httpClient.patch<User>(
			`${this.queryKey}/${userId}/change_password`,
			{ currentPassword, newPassword }
		);

		return response.data;
	};

	changeEmail = async ({
		userId,
		currentPassword,
		email,
	}: {
		userId: UUID;
		currentPassword: string;
		email: string;
	}) => {
		const response = await httpClient.patch<User>(
			`${this.queryKey}/${userId}/change_email`,
			{ currentPassword, email }
		);

		return response.data;
	};

	confirmEmailChange = async (token: string) => {
		await httpClient.get<void>(`${this.queryKey}/confirm_email/${token}`);
	};
}

export default new UserApi();
