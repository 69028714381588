import React, { useEffect } from 'react';
import { Button } from '@/components/partials/button/Button';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '@/store/slices/appSlice';
import { useTypedDispatch } from '@/store/hooks';
import { Box, Flex, Title, Image, Text, useMantineTheme } from '@mantine/core';

import styles from './ErrorPage.module.scss';

interface Props {
	httpCode: string;
}

const baseSlug = 'errorPage.';

export const ErrorPage: React.FC<Props> = ({ httpCode }) => {
	const dispatch = useTypedDispatch();

	useEffect(() => {
		const nav = document.getElementById('navbar'),
			footer = document.querySelector('footer');
		nav?.classList.add('hidden');
		footer?.classList.add('hidden');
		return () => {
			nav?.classList.remove('hidden');
			footer?.classList.remove('hidden');
		};
	}, []);

	const navigate = useNavigate();

	const handleNavigate = () => {
		dispatch(setLoading(false));
		navigate('/');
	};

	const errorMessage = (httpCode: string) => {
		switch (httpCode) {
			case '404':
				return 'Nie odnaleziono rządanego zasobu!';
			case '':
				return 'Ta strona nie istnieje!';
			default:
				return 'Nastąpił nieoczekiwany błąd. Spróbuj ponownie później!';
		}
	};

	return (
		<Flex className={`page-wrapper ${styles.container}`} gap="xl">
			<Box maw={528}>
				<Title order={1} mb="lg">
					{httpCode || '404'}
				</Title>
				<Title order={3} mb="sm">
					qwd
				</Title>
				<Text mb="md">{errorMessage(httpCode)}</Text>

				<Button
					themes={['primary', 'fullWidth']}
					buttonProps={{
						onClick: handleNavigate,
					}}
				>
					qwd
				</Button>
			</Box>

			<Image src={''} maw={700} />
		</Flex>
	);
};
