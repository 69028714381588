import {
	useContext,
	ReactNode,
	useLayoutEffect,
	createContext,
	useState,
} from 'react';
import { useLocalStorage, useSessionStorage } from '@mantine/hooks';
import { User } from '@/types/user';
import {
	LoginValues,
	SubmitStatus,
} from '@/components/views/unauthenticated/login/Login';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { isAppError } from '@/utils/utilities';
import { ErrorCode } from '@/configs/errorCode';
import { AxiosError } from 'axios';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import httpClient from '@/api/httpClient';
import Cookies from 'js-cookie';
import UserApi from '@/api/UserApi';

type LoginResponse =
	| { status: SubmitStatus.SUCCESS }
	| { status: SubmitStatus.ERROR; stack: (ErrorCode | string)[] };

interface TokenPayload extends JwtPayload {
	user: User;
}

interface Context {
	user: User | null;
	changeUser: (newUser?: User | null) => void;
	upatedUser: (data: Partial<User>) => void;
	login: (values: LoginValues) => Promise<LoginResponse>;
	logout: (redirect?: string) => void;
}

const AuthContext = createContext<Context>(null!);

export const useAuth = () => useContext(AuthContext);

const STORAGE_KEY = 'refreshToken';

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const queryClient = useQueryClient();

	const navigate = useNavigate();

	const [user, setUser] = useLocalStorage<User | null>({
		key: 'user',
		defaultValue: null,
		getInitialValueInEffect: false,
	});

	const [sessionRefreshToken, setSessionRefreshToken, clearSessionToken] =
		useSessionStorage<string | undefined>({
			key: STORAGE_KEY,
			defaultValue: Cookies.get(STORAGE_KEY),
			getInitialValueInEffect: false,
		});

	const [refreshTimeout, setRefreshTimeout] = useState<number>();

	useLayoutEffect(() => {
		loginWithResfreshToken(sessionRefreshToken);
	}, []);

	const loginWithResfreshToken = async (refresh?: string) => {
		if (!refresh) {
			logout();
			return;
		}

		try {
			const response = await UnauthenticatedApi.refreshToken(refresh);

			const { accessToken, refreshToken } = response.data;

			const decoded = jwtDecode<TokenPayload>(accessToken);

			const remember = !!Cookies.get(STORAGE_KEY);

			await setAuth(decoded, accessToken, refreshToken, remember);
		} catch (error: any) {
			console.error(error);
			if (error.code !== AxiosError.ECONNABORTED) {
				setUser(null);
				eradicateRefreshToken();
			}
		}
	};

	const refreshTokenWhenExpire = (
		tokenPayload: TokenPayload,
		refresh: string
	) => {
		const tokenLifeSpan =
			tokenPayload.iat && tokenPayload.exp
				? tokenPayload.exp - tokenPayload.iat
				: 0;
		// Refresh 5 sec before token expire
		const refreshTimeout = (tokenLifeSpan - 5) * 1000;

		const id = setTimeout(() => {
			loginWithResfreshToken(refresh);
		}, refreshTimeout);

		setRefreshTimeout(id);
	};

	const login: Context['login'] = async ({ email, password, remember }) => {
		try {
			const response = await UnauthenticatedApi.login({ email, password });

			const { accessToken, refreshToken } = response.data;

			const decoded = jwtDecode<TokenPayload>(accessToken);
			await setAuth(decoded, accessToken, refreshToken, remember);

			return { status: SubmitStatus.SUCCESS };
		} catch (error) {
			const stack = isAppError(error) ? error.stack : [ErrorCode.GENERIC];
			return { stack, status: SubmitStatus.ERROR };
		}
	};

	const logout: Context['logout'] = (redirect) => {
		queryClient.cancelQueries();
		eradicateRefreshToken();
		httpClient.defaults.headers.common['Authorization'] = '';
		setUser(null);
		queryClient.clear();
		clearTimeout(refreshTimeout);
		if (redirect) navigate(redirect);
	};

	const eradicateRefreshToken = () => {
		clearSessionToken();
		Cookies.remove(STORAGE_KEY);
	};

	const saveRefreshToken = (refreshToken: string) => {
		setSessionRefreshToken(refreshToken);
		Cookies.set(STORAGE_KEY, refreshToken, {
			secure: true,
			expires: 360,
		});
	};

	const setAuth = async (
		decoded: TokenPayload,
		accessToken: string,
		refreshToken: string,
		remember = true
	) => {
		const { user } = decoded;

		if (user.userType !== 'user') throw new Error('User type not allowed');

		const fullUser = (
			await httpClient.get<User>(`${UserApi.queryKey}/${user.id}`)
		).data;

		setAuthHeader(accessToken);

		if (remember) saveRefreshToken(refreshToken);
		else setSessionRefreshToken(refreshToken);

		refreshTokenWhenExpire(decoded, refreshToken);

		setUser(fullUser);
	};

	const changeUser: Context['changeUser'] = (newUser = null) =>
		setUser(newUser);

	const upatedUser: Context['upatedUser'] = (data) =>
		setUser((prev) => (prev ? { ...prev, ...data } : null));

	return (
		<AuthContext.Provider
			value={{
				user,
				changeUser,
				upatedUser,
				login,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

function setAuthHeader(token: string) {
	httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
