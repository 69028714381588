import { ReactNode } from 'react';
import {
	Anchor,
	Badge,
	Image,
	Burger,
	Button,
	Divider,
	Group,
	MantineProvider as Mantine,
	MantineTheme,
	MantineThemeOverride,
	Menu,
	Modal,
	Pagination,
	Radio,
	RadioGroup,
	ScrollArea,
	ScrollAreaAutosize,
	Select,
	Tooltip,
	Notification,
	rem,
	Stack,
	Checkbox,
	Popover,
	Accordion,
	BackgroundImage,
	Title,
	Drawer,
	Slider,
	Switch,
	Progress,
	Overlay,
	LoadingOverlay,
	PasswordInput,
	TextInput,
	Loader as MantineLoader,
	CSSVariablesResolver,
	Flex,
	NumberInput,
	Stepper,
	Chip,
	NativeSelect,
	List,
	Skeleton,
	Indicator,
	SegmentedControl,
	MultiSelect,
} from '@mantine/core';
import { Loader } from '@/components/partials/loading/partials/Loader';
import { DateInput, DatePicker } from '@mantine/dates';
import { Dropzone } from '@mantine/dropzone';
import { Carousel } from '@mantine/carousel';
import moment from 'moment';
import XIcon from '@/assets/icons/x-close.svg?react';
import EyeIcon from '@/assets/icons/eye.svg?react';
import EyeOffIcon from '@/assets/icons/eye-off.svg?react';
import ArrowDownIcon from '@/assets/icons/chevron-down.svg?react';
import ArrowRightIcon from '@/assets/icons/chevron-right.svg?react';
import ArrowLeftIcon from '@/assets/icons/chevron-left.svg?react';
import CheckIcon from '@/assets/icons/check.svg?react';
import APP_CONFIG from '@/configs/appConfig';
import classNames from 'classnames';
import styles from '../sass/mantine.module.scss';
import '@mantine/core/styles.layer.css';
import '@mantine/dates/styles.layer.css';
import '@mantine/carousel/styles.layer.css';

const colors: MantineThemeOverride['colors'] = {
	gray: [
		'#fefdfd',
		'#F2F4F7',
		'#eaecf0',
		'#d0d5dd',
		'#98a2b3',
		'#697586',
		'#667085',
		'#344054',
		'#1d2939',
		'#545453',
	],
	dark: [
		'#f8fafc',
		'#eef2f6',
		'#E3E8EF',
		'#cdd5df',
		'#9aa4b2',
		'#697586',
		'#697586',
		'#364152',
		'#202939',
		'#202939',
	],
	green: [
		'#e6f6f4',
		'#e3fbcc',
		'#b0e4dd',
		'#00a991',
		'#009883',
		'#16b364',
		'#51a018',
		'#006557',
		'#004c41',
		'#07353f',
	],
	blue: [
		'#F0F9FF',
		'#e0f2fe',
		'#026AA2',
		'#026AA2',
		'#026AA2',
		'#026AA2',
		'#0086C9',
		'#026AA2',
		'#026AA2',
		'#026AA2',
	],
	red: [
		'#E30613',
		'#FEE4E2',
		'#E30613',
		'#E30613',
		'#E30613',
		'#E30613',
		'#E30613',
		'#E30613',
		'#E30613',
		'#E30613',
	],
};

const components: MantineThemeOverride['components'] = {
	Pagination: Pagination.extend({
		defaultProps: {
			withControls: true,
			gap: 'sm',
			radius: 0,
		},
		classNames: {
			control: styles.paginationControl,
			dots: styles.paginationDots,
		},
	}),
	Burger: Burger.extend({
		defaultProps: {
			color: 'red.6',
			size: 'md',
		},
	}),
	Badge: Badge.extend({
		defaultProps: {
			size: 'md',
			h: 36,
			color: 'white',
			px: 12,
		},
		classNames: {
			root: styles.badgeRoot,
			label: styles.badgeLabel,
		},
	}),
	Modal: Modal.extend({
		defaultProps: {
			centered: true,
			withCloseButton: false,
			radius: 16,
			shadow: 'md',
			size: 'lg',
			zIndex: APP_CONFIG.Z_INDEX.MODAL,
			closeButtonProps: {
				children: <XIcon />,
				iconSize: 0,
			},
			transitionProps: {
				transition: 'slide-down',
			},
		},
		classNames: {
			inner: styles.modalInner,
			content: styles.modalContent,
			body: styles.modalBody,
			header: styles.modalHeader,
			title: styles.modalTitle,
			close: styles.modalClose,
		},
	}),
	Button: Button.extend({
		defaultProps: {
			radius: 0,
		},
	}),
	Menu: Menu.extend({
		defaultProps: {
			shadow: 'sm',
			radius: 8,
			transitionProps: {
				transition: 'slide-down',
			},
		},
		classNames: {
			dropdown: styles.menuDropdown,
			item: styles.menuItem,
		},
	}),
	Divider: Divider.extend({
		defaultProps: {
			color: 'dark.2',
			size: 1,
		},
	}),
	Select: Select.extend({
		defaultProps: {
			rightSection: <ArrowDownIcon />,
			radius: 8,
			fw: 400,
			size: 'lg',
			withCheckIcon: false,
			allowDeselect: false,
			comboboxProps: {
				transitionProps: {
					duration: 100,
					transition: 'scale-y',
					timingFunction: 'ease',
				},
				zIndex: APP_CONFIG.Z_INDEX.SELECT,
			},
		},
		classNames: {
			input: styles.selectInput,
			section: styles.selectSection,
			option: styles.selectOption,
			dropdown: styles.selectDropdown,
			empty: styles.selectEmpty,
			label: styles.selectLabel,
			error: styles.textInputError,
		},
	}),
	MultiSelect: MultiSelect.extend({
		defaultProps: {
			rightSection: <ArrowDownIcon />,
			radius: 8,
			fw: 400,
			size: 'lg',
			withCheckIcon: false,
			hidePickedOptions: true,
			comboboxProps: {
				transitionProps: {
					duration: 100,
					transition: 'scale-y',
					timingFunction: 'ease',
				},
				zIndex: APP_CONFIG.Z_INDEX.SELECT,
			},
		},
		classNames: {
			input: classNames(styles.selectInput, styles.multiSelectInput),
			section: styles.selectSection,
			option: styles.selectOption,
			dropdown: styles.selectDropdown,
			empty: styles.selectEmpty,
			label: styles.selectLabel,
			error: styles.textInputError,
		},
	}),
	NativeSelect: NativeSelect.extend({
		defaultProps: {
			rightSection: <ArrowDownIcon />,
			radius: 8,
			fw: 400,
			size: 'lg',
		},
		classNames: {
			input: styles.selectInput,
			section: styles.selectSection,
			label: styles.selectLabel,
		},
	}),
	Image: Image.extend({
		defaultProps: {
			radius: 0,
			fit: 'contain',
		},
		classNames: {
			root: 'fade-in',
		},
	}),
	ScrollArea: ScrollArea.extend({
		defaultProps: {
			scrollbarSize: 6,
			type: 'always',
			scrollbars: 'y',
		},
		classNames: {
			scrollbar: styles.scrollAreaScrollbar,
			thumb: styles.scrollAreaThumb,
		},
	}),
	ScrollAreaAutosize: ScrollAreaAutosize.extend({
		defaultProps: {
			scrollbarSize: 6,
			type: 'always',
			scrollbars: 'y',
		},
		classNames: {
			scrollbar: styles.scrollAreaScrollbar,
			thumb: styles.scrollAreaThumb,
		},
	}),
	Tooltip: Tooltip.extend({
		defaultProps: {
			position: 'top',
			transitionProps: {
				transition: 'pop',
				duration: 200,
			},
			multiline: true,
			withArrow: true,
			arrowSize: 8,
			events: { hover: true, focus: true, touch: true },
			color: 'dark.0',
			radius: 8,
			zIndex: APP_CONFIG.Z_INDEX.TOOLTIP,
		},
		classNames: {
			tooltip: styles.tooltipTooltip,
		},
	}),
	Group: Group.extend({
		classNames: {
			root: styles.groupRoot,
		},
	}),
	RadioGroup: RadioGroup.extend({
		classNames: {
			label: styles.radioGroupLabel,
		},
	}),
	Radio: Radio.extend({
		defaultProps: {
			size: 'xs',
			color: 'transparent',
		},
		classNames: {
			root: styles.radioRoot,
			body: styles.radioBody,
			labelWrapper: styles.radioLabelWrapper,
			label: styles.radioLabel,
			icon: styles.radioIcon,
			radio: styles.radioRadio,
		},
	}),
	Anchor: Anchor.extend({
		defaultProps: {
			underline: 'always',
			fw: 500,
			c: 'blue.6',
		},
	}),
	Stack: Stack.extend({
		classNames: {
			root: styles.stackRoot,
		},
	}),
	Checkbox: Checkbox.extend({
		defaultProps: {
			size: '22',
			radius: 4,
			color: 'blue.0',
			iconColor: 'blue.6',
			fw: 400,
		},
		classNames: {
			label: styles.checkboxLabel,
			input: styles.checkboxInput,
			icon: styles.checkboxIcon,
			inner: styles.checkboxInner,
		},
	}),
	TextInput: TextInput.extend({
		defaultProps: {
			radius: 'md',
			withAsterisk: false,
			fw: 400,
			leftSectionPointerEvents: 'none',
			pointer: true,
		},
		classNames: {
			input: styles.textInputInput,
			label: 'input-label',
			error: styles.textInputError,
		},
	}),
	NumberInput: NumberInput.extend({
		defaultProps: {
			radius: 'md',
			withAsterisk: false,
			fw: 400,
			hideControls: true,
		},
		classNames: {
			input: styles.numberInputInput,
			control: styles.numberInputControl,
		},
	}),
	PasswordInput: PasswordInput.extend({
		defaultProps: {
			radius: 'md',
			withAsterisk: false,
			fw: 400,
			rightSectionWidth: 32,
			visibilityToggleIcon: ({ reveal }) => (
				<Flex
					className={classNames({
						[styles.passwordInputIconRevealed]: reveal,
					})}
				>
					{reveal ? <EyeOffIcon /> : <EyeIcon />}
				</Flex>
			),
		},
		classNames: {
			wrapper: styles.passwordInputWrapper,
			input: styles.passwordInputInput,
			innerInput: styles.passwordInputInnerInput,
			section: styles.passwordInputSection,
			error: styles.passwordInputError,
			label: styles.passwordInputLabel,
			visibilityToggle: styles.passwordInputVisibilityToggle,
		},
	}),
	Loader: MantineLoader.extend({
		defaultProps: {
			loaders: { ...MantineLoader.defaultLoaders, spinner: Loader },
			type: 'spinner',
		},
	}),
	LoadingOverlay: LoadingOverlay.extend({
		defaultProps: {
			loaderProps: {
				type: 'spinner',
			},
			overlayProps: {
				opacity: 0.5,
			},
			zIndex: APP_CONFIG.Z_INDEX.OVERLAY,
		},
	}),
	Overlay: Overlay.extend({
		defaultProps: {
			zIndex: APP_CONFIG.Z_INDEX.OVERLAY,
			backgroundOpacity: 0.25,
			radius: 12,
		},
	}),
	Progress: Progress.extend({
		defaultProps: {
			size: 'md',
			color: 'green.6',
			radius: 'md',
		},
		styles: ({ colors }) => ({
			root: {
				backgroundColor: colors.green[0],
			},
		}),
	}),
	Slider: Slider.extend({
		defaultProps: {
			labelTransitionProps: {
				transition: 'fade',
				duration: 250,
			},
			thumbSize: 24,
			size: 8,
			color: 'blue.6',
		},
		classNames: {
			thumb: styles.sliderThumb,
			mark: styles.sliderMark,
			markLabel: styles.sliderMarkLabel,
			track: styles.sliderTrack,
			label: styles.sliderLabel,
		},
	}),
	Drawer: Drawer.extend({
		defaultProps: {
			position: 'left',
			zIndex: APP_CONFIG.Z_INDEX.DRAWER,
		},
		classNames: {
			inner: styles.drawerInner,
			body: styles.drawerBody,
		},
	}),
	Title: Title.extend({
		defaultProps: {
			lts: -0.25,
			// textWrap: 'balance',
		},
	}),
	Notification: Notification.extend({
		defaultProps: {
			radius: 6,
			withCloseButton: false,
			icon: false,
			withBorder: false,
		},
		styles: {
			root: {
				zIndex: APP_CONFIG.Z_INDEX.NOTIFICATION,
			},
		},
		classNames: {
			root: styles.notificationRoot,
		},
	}),
	BackgroundImage: BackgroundImage.extend({
		defaultProps: {},
	}),
	Accordion: Accordion.extend({
		defaultProps: {
			chevronPosition: 'left',
			chevronSize: 24,
			transitionDuration: 250,
		},
		classNames: {
			content: styles.accordionContent,
			item: styles.accordionItem,
			control: styles.accordionControl,
			label: styles.accordionLabel,
			chevron: styles.accordionChevron,
		},
	}),
	Switch: Switch.extend({
		defaultProps: {
			color: 'blue.6',
		},
		classNames: {
			label: styles.switchLabel,
		},
	}),
	Popover: Popover.extend({
		defaultProps: {
			shadow: 'md',
			radius: 'md',
			withArrow: false,
		},
		classNames: {
			dropdown: styles.popoverDropdown,
		},
	}),
	Dropzone: Dropzone.extend({
		defaultProps: {
			px: 'lg',
			py: 'md',
		},
		classNames: {
			root: styles.dropzoneRoot,
			inner: styles.dropzoneInner,
		},
	}),
	DateInput: DateInput.extend({
		defaultProps: {
			radius: 'md',
			size: 'lg',
			fw: 400,
			valueFormat: 'DD-MM-YYYY',
			placeholder: 'DD-MM-YYYY',
			defaultLevel: 'decade',
			dateParser: (input) => moment(input, 'DD-MM-YYYY').toDate(),
			popoverProps: {
				radius: 'md',
				zIndex: APP_CONFIG.Z_INDEX.POPOVER,
			},
			withCellSpacing: false,
			monthsListFormat: 'MMMM',
		},
		classNames: {
			input: styles.dateInputInput,
			day: styles.dateInputDay,
			calendarHeader: styles.dateInputCalendarHeader,
			calendarHeaderLevel: styles.dateInputCalendarHeaderLevel,
			calendarHeaderControl: styles.dateInputCalendarHeaderControl,
			calendarHeaderControlIcon: styles.dateInputCalendarHeaderControlIcon,
			weekday: styles.dateInputWeekday,
			monthsListControl: styles.dateInputMonthsListControl,
			yearsListControl: styles.dateInputYearsListControl,
			monthsList: styles.dateInputMonthsList,
			yearsList: styles.dateInputYearsList,
			label: styles.dateInputLabel,
		},
	}),
	DatePicker: DatePicker.extend({
		defaultProps: {
			size: 'lg',
			fw: 400,
			withCellSpacing: false,
		},
		classNames: {
			day: styles.datePickerDay,
			calendarHeader: styles.dateInputCalendarHeader,
			calendarHeaderLevel: styles.dateInputCalendarHeaderLevel,
			calendarHeaderControl: styles.dateInputCalendarHeaderControl,
			calendarHeaderControlIcon: styles.dateInputCalendarHeaderControlIcon,
			weekday: styles.dateInputWeekday,
			monthsListControl: styles.dateInputMonthsListControl,
			yearsListControl: styles.dateInputYearsListControl,
			monthsList: styles.dateInputMonthsList,
			yearsList: styles.dateInputYearsList,
		},
	}),
	Stepper: Stepper.extend({
		defaultProps: {
			size: 'md',
			iconSize: 32,
			completedIcon: <CheckIcon />,
		},
		classNames: {
			stepIcon: styles.stepperStepIcon,
			stepDescription: styles.stepperStepDescription,
			separator: styles.stepperSeparator,
			verticalSeparator: styles.stepperVerticalSeparator,
		},
	}),
	Chip: Chip.extend({
		defaultProps: {
			size: 'md',
			icon: <></>,
		},
		classNames: {
			iconWrapper: styles.chipIconWrapper,
			label: styles.chipLabel,
			input: styles.chipInput,
		},
	}),
	Carousel: Carousel.extend({
		defaultProps: {
			controlSize: 48,
			nextControlIcon: <ArrowRightIcon />,
			previousControlIcon: <ArrowLeftIcon />,
		},
		classNames: {
			control: styles.carouselControl,
		},
	}),
	List: List.extend({
		defaultProps: {
			spacing: 'lg',
			center: true,
		},
		classNames: {
			itemIcon: styles.listItemIcon,
			itemLabel: styles.listItemLabel,
		},
	}),
	Skeleton: Skeleton.extend({
		defaultProps: {
			radius: 'md',
		},
	}),
	Indicator: Indicator.extend({
		defaultProps: {
			color: 'red.5',
			size: 16,
			radius: 50,
		},
	}),
	SegmentedControl: SegmentedControl.extend({
		defaultProps: {
			size: 'lg',
			color: 'blue.6',
			radius: 'md',
		},
		classNames: {
			root: styles.segmentedControlRoot,
		},
	}),
};

const fontSizes: MantineTheme['fontSizes'] = {
	xs: rem(11),
	sm: rem(12),
	md: rem(14),
	lg: rem(16),
	xl: rem(18),
};

const lineHeights: MantineTheme['lineHeights'] = {
	xs: rem(18),
	sm: rem(18),
	md: rem(20),
	lg: rem(24),
	xl: rem(30),
};

const headings: MantineThemeOverride['headings'] = {
	fontFamily: 'Inter, sans-serif',
	fontWeight: '600',
	sizes: {
		h1: {
			fontSize: rem(36),
			lineHeight: rem(44),
			fontWeight: '600',
		},
		h2: {
			fontSize: rem(24),
			lineHeight: rem(32),
		},
		h3: {
			fontSize: rem(18),
			lineHeight: rem(28),
			fontWeight: '600',
		},
		h4: {
			fontSize: rem(16),
			lineHeight: rem(24),
			fontWeight: '600',
		},
	},
};

const spacing: MantineThemeOverride['spacing'] = {
	xs: rem(4),
	sm: rem(8),
	md: rem(16),
	lg: rem(24),
	xl: rem(32),
};

const other: MantineThemeOverride['other'] = {
	notificationProps: {
		info: {
			classNames: {
				root: styles.notificationInfo,
			},
		},
		danger: {
			classNames: {
				root: styles.notificationDanger,
			},
		},
		warning: {},
		success: {
			classNames: {
				root: styles.notificationSuccess,
			},
		},
	},
};

const breakpoints = Object.entries(APP_CONFIG.BREAKPOINTS).reduce<
	MantineThemeOverride['breakpoints']
>((acc, curr) => {
	acc![curr[0].toLowerCase()] = curr[1];
	return acc;
}, {});

const resolver: CSSVariablesResolver = (theme) => ({
	variables: {
		'--cb-icon-size': '200%',
	},
	light: {},
	dark: {},
});

export const MantineProvider = ({ children }: { children: ReactNode }) => {
	return (
		<Mantine
			theme={{
				fontFamily: 'Inter, sans-serif',
				white: '#ffffff',
				black: '#121926',
				spacing,
				other,
				cursorType: 'pointer',
				colors,
				components,
				headings,
				breakpoints,
				primaryColor: 'green',
				primaryShade: 9,
				fontSizes,
				lineHeights,
			}}
			cssVariablesResolver={resolver}
		>
			{children}
		</Mantine>
	);
};
