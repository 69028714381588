import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { EmailConfirmation } from '../views/common/emailConfirmation/EmailConfirmation';
import { Login } from '../views/unauthenticated/login/Login';
import { SetNewPassword } from '@/components/views/unauthenticated/setNewPassword/SetNewPassword';
import { ResetPassword } from '@/components/views/unauthenticated/resetPassword/ResetPassword';
import { AppPaths } from '@/configs/appPaths';
import { UnathWrapper } from '../partials/unathWrapper/UnathWrapper';
import { UserVoucherStep } from '@/store/slices/userVoucherSlice';
import { AddUserVoucher } from '../views/common/addUserVoucher/AddUserVoucher';
import { UserVoucherLocations } from '../views/common/addUserVoucher/steps/userVoucherLocations/UserVoucherLocations';
import { UserVoucherPassengers } from '../views/common/addUserVoucher/steps/userVoucherPassengers/UserVoucherPassengers';
import { UserVoucherVoucher } from '../views/common/addUserVoucher/steps/userVoucherVoucher/UserVoucherVoucher';
import { AddUserVoucherSuccess } from '../views/common/addUserVoucherSuccess/AddUserVoucherSuccess';
import { Box, Flex } from '@mantine/core';
import { UnathFlightNotifications } from '../views/unauthenticated/unathFlightNotifications/UnathFlightNotifications';

export const UnauthenticatedApp: React.FC = () => {
	return (
		<Routes>
			<Route
				path={AppPaths.USER_VOUCHERS_SUCCESS}
				element={
					<Flex h="100dvh" justify="center" align="center" p="lg">
						<Box px="lg" py="xl" className="card">
							<AddUserVoucherSuccess />
						</Box>
					</Flex>
				}
			/>
			<Route
				path={AppPaths.FLIGHT_NOTIFICATIONS}
				element={
					<Box p="lg" className="unauth-add-user-voucher-wrapper">
						<Box
							px="lg"
							py="xl"
							className="card unauth-add-user-voucher-wrapper__inner"
						>
							<UnathFlightNotifications />
						</Box>
					</Box>
				}
			/>
			<Route
				path={`${AppPaths.USER_VOUCHERS}/add`}
				element={
					<Box p="lg" className="unauth-add-user-voucher-wrapper">
						<Box
							px="lg"
							py="xl"
							className="card unauth-add-user-voucher-wrapper__inner"
						>
							<AddUserVoucher />
						</Box>
					</Box>
				}
			>
				<Route index element={<Navigate to={UserVoucherStep.VOUCHER} />} />
				<Route
					path={UserVoucherStep.VOUCHER}
					element={<UserVoucherVoucher />}
				/>
				<Route
					path={UserVoucherStep.PASSANGERS}
					element={<UserVoucherPassengers />}
				/>
				<Route
					path={UserVoucherStep.LOCATION}
					element={<UserVoucherLocations />}
				/>
			</Route>
			<Route path="/" element={<UnathWrapper />}>
				<Route index element={<Navigate to={AppPaths.LOGIN} />} />
				<Route
					path={AppPaths.EMAIL_CONFIRMATION}
					element={<EmailConfirmation />}
				/>
				<Route path={AppPaths.LOGIN} element={<Login />} />
				<Route
					path={`${AppPaths.SET_PASSWORD}/:token`}
					element={<SetNewPassword />}
				/>
				<Route path={AppPaths.RESET_PASSWORD} element={<ResetPassword />} />
			</Route>
			<Route path="*" element={<Navigate to={AppPaths.LOGIN} />} />
		</Routes>
	);
};
