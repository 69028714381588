import React, { useEffect, useState } from 'react';
import {
	Anchor,
	LoadingOverlay,
	Title,
	useMantineTheme,
	Text,
	PasswordInput,
	Notification,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getErrorMessage, validatePassword } from '@/utils/utilities';
import { AppPaths } from '@/configs/appPaths';
import { SubmitStatus } from '../login/Login';
import { ErrorCode } from '@/configs/errorCode';
import { Button } from '@/components/partials/button/Button';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import Logo from '@/assets/images/logo.svg?react';

const initialValues = {
	password: '',
	repeatPassword: '',
};

export const SetNewPassword: React.FC = () => {
	const { spacing, other } = useMantineTheme();

	const navigate = useNavigate();

	const token = useParams().token as string;

	useEffect(() => {
		if (!token) navigate(`/${AppPaths.LOGIN}`);
	}, [navigate, token]);

	const [status, setStatus] = useState(SubmitStatus.THROTTLE);
	const [errorMessage, setErrorMessage] = useState('');

	const form = useForm({
		initialValues,
		validate: {
			password: (value, values) => {
				if (value !== values.repeatPassword)
					return getErrorMessage(ErrorCode.DIFFERENT_PASSWORDS);
				if (!validatePassword(value))
					return getErrorMessage(ErrorCode.PASSWORD_TOO_WEAK);
				return null;
			},
			repeatPassword: (value, values) => {
				if (value !== values.password)
					return getErrorMessage(ErrorCode.DIFFERENT_PASSWORDS);
				if (!validatePassword(value))
					return getErrorMessage(ErrorCode.PASSWORD_TOO_WEAK);
				return null;
			},
		},
	});

	const handleSubmit = async () => {
		setStatus(SubmitStatus.LOADING);
		const response = await UnauthenticatedApi.resetPassword(
			token,
			form.values.password
		);
		form.reset();

		if (response === SubmitStatus.SUCCESS) {
			setStatus(SubmitStatus.SUCCESS);
			return;
		}

		setErrorMessage(getErrorMessage(response.message));
		setStatus(SubmitStatus.ERROR);
	};

	return (
		<form onSubmit={form.onSubmit(handleSubmit)}>
			<LoadingOverlay visible={status === SubmitStatus.LOADING} />
			<Logo />
			<Title order={1} c="blue.8" my="lg">
				Zresetuj hasło
			</Title>
			<Text size="lg" mb="lg">
				Wpisz nowe hasło dla swojego konta.
			</Text>
			{status === SubmitStatus.ERROR && (
				<Notification {...other.notificationProps.danger} mb="lg">
					<Text fw={500}>{errorMessage}</Text>
				</Notification>
			)}
			{status === SubmitStatus.SUCCESS && (
				<Notification {...other.notificationProps.success} mb="lg">
					<Text fw={500}>Pomyślnie ustawiono nowe hasło!</Text>
				</Notification>
			)}
			<PasswordInput
				{...form.getInputProps('password')}
				size="lg"
				required
				mb="lg"
				label="Hasło"
				placeholder="Hasło"
			/>
			<PasswordInput
				{...form.getInputProps('repeatPassword')}
				size="lg"
				required
				mb="lg"
				label="Powtórzone hasło"
				placeholder="Powtórzone hasło"
			/>

			<Button
				themes={['primary', 'fullWidth']}
				buttonProps={{
					type: 'submit',
					style: {
						marginBottom: spacing.lg,
					},
				}}
			>
				Chcę otrzymać link do resetu hasła
			</Button>
			<Text size="lg">
				Wróć do ekranu{' '}
				<Anchor
					c="black"
					fw={600}
					component={Link}
					to={`/${AppPaths.LOGIN}`}
					underline="hover"
				>
					logowania.
				</Anchor>
			</Text>
		</form>
	);
};
