import React, { useEffect } from 'react';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import {
	UserVoucherStep,
	setUserVoucherStep,
} from '@/store/slices/userVoucherSlice';
import { Stepper } from '@mantine/core';
import { useCounter, useMediaQuery } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { AppPaths } from '@/configs/appPaths';
import APP_CONFIG from '@/configs/appConfig';

const stepsKeys = Object.keys(
		UserVoucherStep
	) as (keyof typeof UserVoucherStep)[],
	stepValues = Object.values(UserVoucherStep),
	stepsAmount = stepsKeys.length;

export const UserVoucherStepper: React.FC = () => {
	const dispatch = useTypedDispatch();

	const isMobile = useMediaQuery(`(max-width: ${APP_CONFIG.BREAKPOINTS.SM})`);

	const { currentStep, enabledSteps } = useTypedSelector(
		(state) => state.userVoucher.options
	);

	const navigate = useNavigate();

	const [count, handlers] = useCounter(0, { min: 0, max: stepsAmount });
	useEffect(() => {
		dispatch(setUserVoucherStep(UserVoucherStep[stepsKeys[count]]));
		navigate(
			`/${AppPaths.USER_VOUCHERS}/add/${UserVoucherStep[stepsKeys[count]]}`
		);
	}, [count]);

	useEffect(() => {
		const index = stepValues.findIndex((key) => key === currentStep);
		handlers.set(index);
	}, [currentStep]);

	return (
		<Stepper
			active={count}
			onStepClick={handlers.set}
			mb="xl"
			orientation={isMobile ? 'vertical' : 'horizontal'}
		>
			<Stepper.Step
				label="Dodaj voucher"
				description="Wpisz dane z vouchera"
			></Stepper.Step>
			<Stepper.Step
				disabled={!enabledSteps.includes(UserVoucherStep.PASSANGERS)}
				label="Dane pasażerów"
				description="Podaj dane pasażerów"
			></Stepper.Step>
			<Stepper.Step
				disabled={!enabledSteps.includes(UserVoucherStep.LOCATION)}
				label="Lokalizacja lotu"
				description="Wybierz lokalizację lotu"
				styles={
					isMobile
						? {
								step: {
									minHeight: 'unset',
								},
						  }
						: undefined
				}
			></Stepper.Step>
		</Stepper>
	);
};
