import { Attachment, UUID } from '@/types/types';
import { QueryKey } from '@/configs/queryKeys';
import EntityApi from './EntityApi';

export interface Partner {
	id: UUID;
	name: string;
	image: Attachment;
	instruction: Attachment;
	instructionImage: Attachment;
	listOrder: number;
	validationSchema?: string;
}

class PartnerApi extends EntityApi<Partner> {
	constructor() {
		super(QueryKey.partners);
	}
}

export default new PartnerApi();
