import React from 'react';
import { Box, Flex, Text, Title, Tooltip, UnstyledButton } from '@mantine/core';
import { Link } from 'react-router-dom';
import { AppPaths } from '@/configs/appPaths';
import { useAuth } from '@/providers/AuthProvider';
import PlusIcon from '@/assets/icons/plus.svg?react';
import CartIcon from '@/assets/icons/shopping-cart-01.svg?react';
import LoginIcon from '@/assets/icons/log-in-01.svg?react';
import TicketIcon from '@/assets/icons/ticket-02.svg?react';
import APP_CONFIG from '@/configs/appConfig';

export const AddUserVoucherSuccess: React.FC = () => {
	const user = useAuth().user;

	return (
		<Box maw={APP_CONFIG.CONTENT_MAX_WIDTH} m="auto" ta="center">
			<Title order={2} c="blue.7" my="xl">
				Voucher został pomyślnie dodany
			</Title>
			<Text mb="xl" fw={500} c="dark.6">
				{user
					? 'Co chcesz zrobić teraz?'
					: 'Dla podanych danych utworzone zostało konto. Sprawdź skrzynkę pocztową i aktywuj je.'}
			</Text>
			<Flex
				gap={{
					base: 'md',
					sm: 'xl',
				}}
				justify="center"
				direction={{
					base: 'column',
					sm: 'row',
				}}
			>
				<UnstyledButton
					className="button-gray-with-icon"
					component={Link}
					to={`/${AppPaths.USER_VOUCHERS}/add`}
				>
					<PlusIcon className="svg-danger" />
					<Text size="lg" fw={500}>
						Dodaj kolejny voucher
					</Text>
				</UnstyledButton>
				{user && (
					<UnstyledButton
						className="button-gray-with-icon"
						component={Link}
						to={`/${AppPaths.VOUCHERS}/purchase`}
					>
						<CartIcon className="svg-danger" />
						<Text size="lg" fw={500}>
							Kup voucher
						</Text>
					</UnstyledButton>
				)}
				{user && (
					<UnstyledButton
						className="button-gray-with-icon"
						component={Link}
						to={`/${AppPaths.USER_VOUCHERS}`}
					>
						<TicketIcon className="svg-danger" />
						<Text size="lg" fw={500}>
							Koniec na dzisiaj
						</Text>
					</UnstyledButton>
				)}
				{!user && (
					<UnstyledButton
						className="button-gray-with-icon"
						component={Link}
						to={`/${AppPaths.LOGIN}`}
					>
						<LoginIcon className="svg-danger" />
						<Text size="lg" fw={500}>
							Zaloguj się
						</Text>
					</UnstyledButton>
				)}
			</Flex>
		</Box>
	);
};
