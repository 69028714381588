import React, { FormEventHandler, useLayoutEffect, useMemo } from 'react';
import {
	Anchor,
	Box,
	Divider,
	Flex,
	LoadingOverlay,
	Notification,
	Text,
	useMantineTheme,
} from '@mantine/core';
import { Button } from '@/components/partials/button/Button';
import { Outlet, useBeforeUnload, useNavigate } from 'react-router-dom';
import { AppPaths } from '@/configs/appPaths';
import { getEmptyPassenger, handleUnload } from '@/utils/utilities';
import { UserVoucherStepper } from './steps/userVoucherStepper/UserVoucherStepper';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { useScrollIntoView } from '@mantine/hooks';
import {
	UserVoucherStep,
	resetUserVoucherState,
} from '@/store/slices/userVoucherSlice';
import { useAuth } from '@/providers/AuthProvider';
import { useMutation } from '@tanstack/react-query';
import UserVoucherApi from '@/api/UserVoucherApi';
import APP_CONFIG from '@/configs/appConfig';
import PhoneIcon from '@/assets/icons/phone.svg?react';
import MailIcon from '@/assets/icons/mail-02.svg?react';

export const AddUserVoucher: React.FC = () => {
	const user = useAuth().user;

	const { other } = useMantineTheme();

	const dispatch = useTypedDispatch();

	const { voucher, options } = useTypedSelector((state) => state.userVoucher);

	const { scrollIntoView, targetRef } = useScrollIntoView<HTMLFormElement>({
		offset: 100,
		duration: 0,
	});

	const navigate = useNavigate();

	useLayoutEffect(() => {
		dispatch(resetUserVoucherState());
	}, []);

	useLayoutEffect(() => {
		scrollIntoView();
	}, [options.currentStep]);

	const navigationLinks = useMemo(() => {
			switch (options.currentStep) {
				case UserVoucherStep.VOUCHER:
					return { prev: '', next: UserVoucherStep.PASSANGERS };
				case UserVoucherStep.PASSANGERS:
					return {
						prev: UserVoucherStep.VOUCHER,
						next: UserVoucherStep.LOCATION,
					};
				case UserVoucherStep.LOCATION:
					return { prev: UserVoucherStep.PASSANGERS, next: '' };
			}
		}, [options.currentStep]),
		isNextEnabled = useMemo(() => {
			switch (options.currentStep) {
				case UserVoucherStep.VOUCHER:
					return options.enabledSteps.includes(UserVoucherStep.PASSANGERS);
				case UserVoucherStep.PASSANGERS:
					return options.enabledSteps.includes(UserVoucherStep.LOCATION);
				case UserVoucherStep.LOCATION:
					return !!voucher.locations.length && options.termsAccepted;
			}
		}, [
			options.currentStep,
			options.enabledSteps,
			voucher.locations,
			options.termsAccepted,
		]),
		isSubmitEnabled = useMemo(
			() => !!voucher.locations.length && options.termsAccepted,
			[voucher.locations, options.termsAccepted]
		);

	useBeforeUnload(handleUnload);

	const createMutation = useMutation({
		mutationFn: UserVoucherApi.createWithImage,
		onSuccess: () => {
			dispatch(resetUserVoucherState());
			navigate(`/${AppPaths.USER_VOUCHERS_SUCCESS}`);
		},
	});

	const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();

		createMutation.mutate({
			data: {
				...voucher,
				passengers: options.skipPassangersForm
					? new Array(voucher.passengersAmount)
							.fill(null)
							.map(getEmptyPassenger)
					: voucher.passengers,
				expirationDate: new Date(voucher.expirationDate!).toISOString(),
				partner: voucher.partner!,
				user,
				contactUser: user ? undefined : voucher.contactUser,
			},
			image: voucher.image!,
		});
	};

	return (
		<Box
			component="form"
			maw={APP_CONFIG.CONTENT_MAX_WIDTH}
			onSubmit={handleSubmit}
			ref={targetRef}
		>
			<LoadingOverlay visible={createMutation.isPending} />
			<UserVoucherStepper />
			{createMutation.isError && (
				<Notification {...other.notificationProps.danger} mb="lg">
					<Text fw={500}>{createMutation.error.message}</Text>
				</Notification>
			)}
			<Outlet />
			<Divider mb="lg" />
			<Flex
				justify={
					options.currentStep === UserVoucherStep.VOUCHER
						? 'flex-end'
						: 'space-between'
				}
				gap="md"
				direction={{
					base: 'column-reverse',
					sm: 'row',
				}}
			>
				{options.currentStep !== UserVoucherStep.VOUCHER && (
					<Button
						isLink
						to={`/${AppPaths.USER_VOUCHERS}/add/${navigationLinks.prev}`}
						themes={['secondary', 'arrowPrev']}
					>
						Wróć
					</Button>
				)}
				{options.currentStep !== UserVoucherStep.LOCATION && (
					<Button
						isLink
						to={`/${AppPaths.USER_VOUCHERS}/add/${navigationLinks.next}`}
						themes={['primary', 'arrowNext']}
						disabled={!isNextEnabled}
					>
						Dalej
					</Button>
				)}
				{options.currentStep === UserVoucherStep.LOCATION && (
					<Button
						themes={['primary', 'arrowNext']}
						buttonProps={{
							type: 'submit',
						}}
						disabled={!isSubmitEnabled}
					>
						Wyślij
					</Button>
				)}
			</Flex>
			{!user && (
				<Flex
					mt="md"
					gap="md"
					direction={{
						base: 'column',
						md: 'row',
					}}
				>
					<Anchor td="none" href="tel:600310310">
						<Flex gap="sm" align="center">
							<PhoneIcon className="svg-danger" />
							<Text c="dark.5" fw={500}>
								600 310 310
							</Text>
						</Flex>
					</Anchor>
					<Anchor td="none" href="mailto:realizacje@wyprawabalonem.pl">
						<Flex gap="sm" align="center">
							<MailIcon className="svg-danger" />
							<Text c="dark.5" fw={500}>
								realizacje@wyprawabalonem.pl
							</Text>
						</Flex>
					</Anchor>
				</Flex>
			)}
		</Box>
	);
};
