import React from 'react';
import { BackgroundImage, Box, Flex } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import bgImage from '@/assets/images/bg-baloon.jpg';
import styles from './UnathWrapper.module.scss';

export const UnathWrapper: React.FC = () => {
	return (
		<Flex mih="100dvh">
			<Box className={styles.formWrapper} p="md" pos="relative">
				<Outlet />
			</Box>
			<Box className={styles.imageSide}>
				<BackgroundImage
					src={bgImage}
					h="100%"
					bgsz={350}
					bgp="right -25px"
					bgr="no-repeat"
				></BackgroundImage>
			</Box>
		</Flex>
	);
};
