import { configureStore } from '@reduxjs/toolkit';
import { StoreKey } from '@/configs/storeKeys';
import appReducer from './slices/appSlice';
import userVoucherReducer from './slices/userVoucherSlice';
import cartReducer from './slices/cartSlice';

export const store = configureStore({
	reducer: {
		[StoreKey.APP]: appReducer,
		[StoreKey.CART]: cartReducer,
		[StoreKey.USER_VOUCHER]: userVoucherReducer,
	},
	devTools: import.meta.env.DEV,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
