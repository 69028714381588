import { Attachment, UUID } from '@/types/types';
import { QueryKey } from '@/configs/queryKeys';
import { Partner } from './PartnerApi';
import { User } from '@/types/user';
import { UserVoucherPassenger } from './UserVoucherPassengerApi';
import { UserVoucherFormFile } from '@/store/slices/userVoucherSlice';
import { base64ToFile } from '@/utils/utilities';
import { Location } from '@/api/LocationApi';
import { Voucher } from './VoucherApi';
import httpClient from './httpClient';
import EntityApi from './EntityApi';
import AppApi from './AppApi';

export enum UserVoucherStatus {
	PENDING,
	ACCEPTED,
	USED,
	MISSING_DATA,
	DISABLED,
}

export interface UserVoucher {
	id: UUID;
	voucherCode: string;
	expirationDate: string;
	image: Attachment;
	locations: Location[];
	user: User;
	passengers: UserVoucherPassenger[];
	status: UserVoucherStatus;
	voucherNumber?: string;
	partner?: Partner;
	voucher?: Voucher;
	passengersAmount: number;
	contactUser?: {
		firstName: string;
		lastName: string;
		email: string;
		phoneNumber: string;
	};
}

class UserVoucherApi extends EntityApi<UserVoucher> {
	constructor() {
		super(QueryKey.userVouchers);
		this.createWithImage = this.createWithImage.bind(this);
	}

	async createWithImage({
		data,
		image,
	}: {
		data: Partial<Omit<UserVoucher, 'image' | 'user'>> & { user: User | null };
		image: UserVoucherFormFile;
	}) {
		const attachment = await AppApi.createAttachment(
			base64ToFile(image.data, image.name, image.type)
		);

		const response = await httpClient.post<UserVoucher>(this.queryKey, {
			...data,
			image: attachment,
		});

		return response.data;
	}
}

export default new UserVoucherApi();
