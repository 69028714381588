import React from 'react';
import { Loader } from './partials/Loader';
import { Box, BoxProps } from '@mantine/core';
import styles from './Loading.module.scss';

interface Props extends BoxProps {}

export const Loading: React.FC<Props> = (props) => {
	return (
		<Box className={styles.container} {...props}>
			<Loader />
		</Box>
	);
};
