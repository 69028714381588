import { Attachment, UUID } from '@/types/types';
import { QueryKey } from '@/configs/queryKeys';
import { StartingPoint } from './StartingPointApi';
import EntityApi from './EntityApi';

export interface Location {
	id: UUID;
	name: string;
	description: string;
	gallery: Attachment[];
	disabled: boolean;
	startingPoints: StartingPoint[];
	options: {
		top: number;
		left: number;
		textProps?: Record<string, unknown>;
	};
}

class LocationApi extends EntityApi<Location> {
	constructor() {
		super(QueryKey.locations);
	}
}

export default new LocationApi();
