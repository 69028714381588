import React from 'react';
import {
	Box,
	Chip,
	Divider,
	Flex,
	Popover,
	TextInput,
	Title,
	Notification,
	Text,
	useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { LocationsMultiSelect } from '../../authenticated/availableFlights/partials/localizationMultiSelect/LocationsMultiSelect';
import { Location } from '@/api/LocationApi';
import { LocationPills } from '@/components/partials/locationPills/LocationPills';
import { Button } from '@/components/partials/button/Button';
import { DatePicker } from '@mantine/dates';
import { useMutation } from '@tanstack/react-query';
import { pick } from 'lodash';
import FlightNotificationApi, {
	FlightNotification,
	FlightNotificationDateType,
} from '@/api/FlightNotificationApi';
import APP_CONFIG from '@/configs/appConfig';

export type NotificationType = keyof Pick<
	FlightNotification,
	'sendSms' | 'sendEmail'
>;

export type FlightNotificationFormValues = {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	notificationTypes: NotificationType[];
	dateType: FlightNotificationDateType;
	locations: Location[];
	selectedDates: Date[];
};

export const UnathFlightNotifications: React.FC = () => {
	const { other } = useMantineTheme();

	const form = useForm<FlightNotificationFormValues>({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			phoneNumber: '',
			notificationTypes: [],
			dateType: FlightNotificationDateType.ALL,
			locations: [],
			selectedDates: [],
		},
		transformValues: (values) => ({
			...values,
			dateType: Number(values.dateType),
		}),
	});

	const handleRemoveLocation = (location: Location) => () => {
		form.setFieldValue(
			'locations',
			form.values.locations.filter((loc) => loc.id !== location.id)
		);
	};

	const submitMutation = useMutation({
		mutationFn: FlightNotificationApi.create,
		onSuccess: () => {
			form.reset();
		},
	});

	const handleSubmit = form.onSubmit((values) => {
		const payload = {
			...pick(values, [
				'firstName',
				'lastName',
				'email',
				'phoneNumber',
				'locations',
				'dateType',
			]),
			sendSms: values.notificationTypes.includes('sendSms'),
			sendEmail: values.notificationTypes.includes('sendEmail'),
			selectedDates: values.selectedDates.map((date) => date.toISOString()),
		};

		submitMutation.mutate(payload);
	});

	const isDisabled =
		!form.values.notificationTypes.length || !form.values.locations.length;

	return (
		<Box
			maw={APP_CONFIG.CONTENT_MAX_WIDTH}
			component="form"
			onSubmit={handleSubmit}
		>
			<Title order={2} c="blue.7" mb="xl">
				Zapisy na powiadomienia o lotach
			</Title>
			{submitMutation.isSuccess && (
				<Notification {...other.notificationProps.success} mb="md">
					<Text fw={500}>
						Twoje zgłoszenie zostało przyjęte. Wkrótce otrzymasz pierwsze
						powiadomienia.
					</Text>
				</Notification>
			)}
			{submitMutation.isError && (
				<Notification {...other.notificationProps.danger} mb="md">
					<Text fw={500}>{submitMutation.error.message}</Text>
				</Notification>
			)}
			<Flex
				gap={{ md: 'xl' }}
				direction={{
					base: 'column',
					md: 'row',
				}}
			>
				<div className="input-col">
					<TextInput
						size="lg"
						label="Imię"
						placeholder="Wpisz imię"
						mb="lg"
						required
						{...form.getInputProps('firstName')}
					/>
					<TextInput
						size="lg"
						label="Adres e-mail"
						type="email"
						placeholder="Wpisz adres e-mail"
						mb="lg"
						required
						{...form.getInputProps('email')}
					/>
				</div>
				<div className="input-col">
					<TextInput
						size="lg"
						label="Nazwisko"
						placeholder="Wpisz nazwisko"
						mb="lg"
						required
						{...form.getInputProps('lastName')}
					/>
					<TextInput
						size="lg"
						label="Numer telefonu"
						placeholder="--- ---- ----"
						type="tel"
						mb="lg"
						required
						{...form.getInputProps('phoneNumber')}
					/>
				</div>
			</Flex>
			<Box mb="lg">
				<label className="label">Typ powiadomień</label>
				<Chip.Group multiple {...form.getInputProps('notificationTypes')}>
					<Flex gap={12}>
						<Chip value="sendSms">SMS</Chip>
						<Chip value="sendEmail">E-mail</Chip>
					</Flex>
				</Chip.Group>
			</Box>
			<Box mb="lg">
				<label className="label">Wybierz termin</label>
				<Chip.Group
					{...form.getInputProps('dateType')}
					value={form.values.dateType?.toString()}
				>
					<Flex gap={12}>
						<Chip value={FlightNotificationDateType.ALL.toString()}>
							Interesuje mnie każdy termin
						</Chip>
						<Chip value={FlightNotificationDateType.WEEKENDS.toString()}>
							Interesują mnie tylko weekendy
						</Chip>
						<Chip value={FlightNotificationDateType.MORNINGS.toString()}>
							Interesują mnie tylko poranki
						</Chip>
						<Popover withArrow shadow="md" position="bottom">
							<Popover.Target>
								<Chip value={FlightNotificationDateType.SELECTED.toString()}>
									Interesują mnie tylko wybrane terminy
								</Chip>
							</Popover.Target>
							<Popover.Dropdown>
								<DatePicker
									size="md"
									type="multiple"
									minDate={new Date()}
									{...form.getInputProps('selectedDates')}
								/>
							</Popover.Dropdown>
						</Popover>
					</Flex>
				</Chip.Group>
			</Box>
			<Flex direction="column" gap="md" align="flex-start" mb="lg">
				<LocationsMultiSelect
					locations={form.values.locations}
					setLocations={(locations) =>
						form.setFieldValue('locations', locations)
					}
					max={APP_CONFIG.MAX_LOCATIONS_LENGTH}
				/>
				<LocationPills
					locations={form.values.locations}
					handleRemove={handleRemoveLocation}
				/>
			</Flex>
			<Divider mb="lg" />
			<Flex justify="flex-end">
				<Button
					themes={['primary']}
					disabled={isDisabled}
					buttonProps={{
						type: 'submit',
					}}
				>
					Wyślij
				</Button>
			</Flex>
		</Box>
	);
};
