import { UUID } from '@/types/types';
import { QueryKey } from '@/configs/queryKeys';
import { UserVoucherPassenger } from './UserVoucherPassengerApi';
import { UserVoucher } from './UserVoucherApi';
import { User } from '@/types/user';
import EntityApi from './EntityApi';

export enum FlightPassengerStatus {
	REGISTERED,
	ACCEPTED,
	REJECTED,
	RESIGNED,
	ACCEPTED_TO_ANOTHER_FLIGHT,
	WANTS_TO_SIGN_OFF,
	SUSPENDED,
}

export interface FlightPassenger {
	id: UUID;
	userVouchers: UserVoucher[];
	passengers: UserVoucherPassenger[];
	user: User;
	status: FlightPassengerStatus;
}

class FlightApi extends EntityApi<FlightPassenger> {
	constructor() {
		super(QueryKey.flightPassengers);
	}
}

export default new FlightApi();
