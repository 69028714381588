import { Attachment } from '@/types/types';
import httpClient from './httpClient';

class AppApi {
	async createAttachment(file: File | Blob) {
		const attachmentBody = new FormData();
		attachmentBody.append('file', file);

		const response = await httpClient.post<Attachment>(
			'attachments',
			attachmentBody
		);

		return response.data;
	}
}

export default new AppApi();
