import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/store/index';
import { StoreKey } from '@/configs/storeKeys';
import { Cart } from '@/api/CartApi';
import { UUID } from '@/types/types';

export interface UserVoucherOptions {
	isInitialized: boolean;
	addToCartModalOpened: boolean;
}

interface SliceState {
	cart: Cart;
	options: UserVoucherOptions;
}

const initialState: SliceState = {
	cart: {
		id: crypto.randomUUID(),
		cartItems: [],
		price: {
			regularPrice: 0,
			discount: 0,
			price: 0,
		},
		discountCodes: [],
	},
	options: {
		isInitialized: false,
		addToCartModalOpened: true,
	},
};

export const cartSlice = createSlice({
	name: StoreKey.USER_VOUCHER,
	initialState,
	reducers: {
		setCart: (state, action: PayloadAction<Partial<Cart>>) => {
			Object.assign(state.cart, action.payload);
		},
		setOptions: (state, action: PayloadAction<Partial<UserVoucherOptions>>) => {
			Object.assign(state.options, action.payload);
		},
		deleteCartItem: (state, action: PayloadAction<UUID>) => {
			state.cart.cartItems = state.cart.cartItems.filter(
				(item) => item.id !== action.payload
			);
		},
		toggleAddToCartModal: (state) => {
			state.options.addToCartModalOpened = !state.options.addToCartModalOpened;
		},
		resetCartState: (state) => {
			Object.assign(state, initialState);
		},
	},
});

export const {
	setCart,
	setOptions,
	deleteCartItem,
	toggleAddToCartModal,
	resetCartState,
} = cartSlice.actions;

export const selectCartState = (state: RootState) =>
	state[StoreKey.USER_VOUCHER];
export default cartSlice.reducer;
