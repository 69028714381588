import { ApiPaginatedResponse, Attachment, UUID } from '@/types/types';
import { QueryKey } from '@/configs/queryKeys';
import { Balloon } from './BalloonApi';
import { StartingPoint } from './StartingPointApi';
import { Voucher } from './VoucherApi';
import { Location } from './LocationApi';
import { QueryFunctionContext } from '@tanstack/react-query';
import { DatesRangeValue } from '@mantine/dates';
import { AxiosRequestConfig } from 'axios';
import { formatDateForAPI } from '@/utils/utilities';
import { FlightPassenger } from './FlightPassengerApi';
import { Pilot } from '@/types/user';
import EntityApi from './EntityApi';
import httpClient from './httpClient';

export enum FlightTypeFilter {
	ALL = 'ALL',
	RESERVE = 'RESERVE',
	MAIN = 'MAIN',
}

export enum FlightStatus {
	DISABLED,
	PLANNED,
	CANCELLED,
	FINISHED,
	ACCEPTED,
}

export interface Flight {
	id: UUID;
	balloon: Balloon;
	location: Location;
	startingPoint: StartingPoint;
	status: FlightStatus;
	pilot: Pilot;
	temperature: number;
	startDate: string;
	publicationDate: string;
	createdAt: string;
	vouchers: Voucher[];
	flightPassenger?: FlightPassenger;
	gallery: Attachment[];
	maxCapacity: number;
	currentCapacity: number;
	reserveFlight?: Flight;
	mainFlight?: Flight;
	cancelNote?: string;
}

class FlightApi extends EntityApi<Flight> {
	constructor() {
		super(QueryKey.flights);
		this.signUp = this.signUp.bind(this);
		this.signOff = this.signOff.bind(this);
	}

	async getFiltered({
		queryKey,
	}: QueryFunctionContext<
		[
			QueryKey,
			{
				dateFilters: DatesRangeValue;
				locations: Location[];
				flightTypeFilter: FlightTypeFilter;
			}
		]
	>) {
		const params: AxiosRequestConfig['params'] = {
			limit: 0,
			'filter.status': FlightStatus.PLANNED,
			'order[startDate]': 'desc',
		};

		if (queryKey[1].dateFilters[0] && queryKey[1].dateFilters[1])
			params['filter.startDate'] = `$btw:${formatDateForAPI(
				queryKey[1].dateFilters[0]
			)},${formatDateForAPI(queryKey[1].dateFilters[1])}`;

		if (queryKey[1].locations.length)
			params['filter.location.id'] = `$in:${queryKey[1].locations
				.map((location) => location.id)
				.join(',')}`;

		if (queryKey[1].flightTypeFilter === FlightTypeFilter.RESERVE)
			params['filter.mainFlight'] = '$not:$null';
		if (queryKey[1].flightTypeFilter === FlightTypeFilter.MAIN)
			params['filter.reserveFlight'] = '$not:$null';

		const response = await httpClient.get<ApiPaginatedResponse<Flight>>(
			queryKey[0],
			{
				params,
			}
		);

		return response.data.data;
	}

	async signUp({
		flight,
		userVouchers,
	}: {
		flight: Flight;
		userVouchers: string[];
	}) {
		const response = await httpClient.patch<Flight>(
			`${this.queryKey}/${flight.id}/sign`,
			{
				userVouchers: userVouchers.map((id) => ({ id })),
			}
		);

		return response.data;
	}

	async signOff(flightId: UUID) {
		const response = await httpClient.patch<Flight>(
			`${this.queryKey}/${flightId}/sign-off`,
			{}
		);

		return response.data;
	}
}

export default new FlightApi();
