import React, { useEffect, useMemo, useState } from 'react';
import {
	Checkbox,
	Divider,
	Popover,
	ScrollArea,
	Stack,
	Text,
	TextInput,
	UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Button } from '@/components/partials/button/Button';
import { useQuery } from '@tanstack/react-query';
import { UUID } from '@/types/types';
import LocationApi, { Location } from '@/api/LocationApi';
import ArrowDownIcon from '@/assets/icons/chevron-down.svg?react';
import SearchIcon from '@/assets/icons/search-sm.svg?react';
import classNames from 'classnames';
import styles from './LocationsMultiSelect.module.scss';

interface Props {
	locations: Location[];
	setLocations: (locations: Location[]) => void;
	max?: number;
}

export const LocationsMultiSelect: React.FC<Props> = ({
	locations,
	setLocations,
	max,
}) => {
	const locationsQuery = useQuery({
		queryKey: [LocationApi.queryKey],
		queryFn: LocationApi.getAll,
		placeholderData: [],
		staleTime: Infinity,
	});

	const [isOpen, { toggle }] = useDisclosure(false);
	const [search, setSearch] = useState('');

	const [innerValues, setInnerValues] = useState(
		locations.map((loc) => loc.id)
	);
	const handleInnerValuesChange = (values: UUID[]) => {
		if (max && values.length > max) return;
		setInnerValues(values);
	};

	useEffect(() => {
		handleInnerValuesChange(locations.map((loc) => loc.id));
	}, [locations]);
	useEffect(() => {
		if (isOpen) return;
		setSearch('');
		handleInnerValuesChange(locations.map((loc) => loc.id));
	}, [isOpen]);

	const filteredData = useMemo<Location[]>(
		() =>
			locationsQuery.data!.filter((loc) =>
				loc.name.toLowerCase().includes(search.toLowerCase())
			),
		[locationsQuery.data, search]
	);

	const handleSave = () => {
		setLocations(
			innerValues.map(
				(id) => locationsQuery.data!.find((loc) => loc.id === id)!
			)
		);
		toggle();
	};

	return (
		<Popover
			width={310}
			position="bottom-start"
			shadow="md"
			opened={isOpen}
			onChange={toggle}
		>
			<Popover.Target>
				<UnstyledButton
					onClick={toggle}
					className={classNames(styles.button, {
						[styles.buttonActive]: isOpen,
					})}
					disabled={!filteredData.length}
				>
					<Text fw={600}>Wybierz lokalizację</Text>
					<ArrowDownIcon width={20} height={20} />
				</UnstyledButton>
			</Popover.Target>
			<Popover.Dropdown>
				<TextInput
					size="md"
					mb="md"
					leftSection={<SearchIcon width={20} height={20} />}
					classNames={{
						input: styles.searchInput,
						section: styles.searchIcon,
					}}
					placeholder="Szukaj lokalizacji"
					value={search}
					onChange={(event) => setSearch(event.currentTarget.value)}
				/>
				<ScrollArea.Autosize mah={175} mb="md">
					<Checkbox.Group
						pr="md"
						value={innerValues}
						onChange={(values) => handleInnerValuesChange(values as UUID[])}
					>
						<Stack gap="md">
							{filteredData.map((location) => (
								<Checkbox
									key={location.id}
									label={location.name}
									value={location.id}
									classNames={{
										body: styles.checkboxBody,
										input: styles.checkboxInput,
										label: styles.checkboxLabel,
									}}
									tt="capitalize"
								/>
							))}
						</Stack>
					</Checkbox.Group>
				</ScrollArea.Autosize>
				<Divider mb="lg" />
				<Button
					themes={['primary', 'fullWidth']}
					buttonProps={{
						type: 'button',
						onClick: handleSave,
						style: {
							height: 36,
						},
					}}
				>
					Zapisz
				</Button>
			</Popover.Dropdown>
		</Popover>
	);
};
