import React, { useEffect, useState } from 'react';
import { Button } from '@/components/partials/button/Button';
import { useAuth } from '@/providers/AuthProvider';
import {
	LoadingOverlay,
	TextInput,
	Title,
	Text,
	useMantineTheme,
	PasswordInput,
	Checkbox,
	Notification,
	Box,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Link, useSearchParams } from 'react-router-dom';
import { getErrorMessage, isApiError } from '@/utils/utilities';
import { AppPaths } from '@/configs/appPaths';
import { Anchor } from '@mantine/core';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import Logo from '@/assets/images/logo.svg?react';

export interface LoginValues {
	email: string;
	password: string;
	remember?: boolean;
}

export enum SubmitStatus {
	THROTTLE,
	LOADING,
	ERROR,
	SUCCESS,
}

const initialValues = {
	email: '',
	password: '',
	remember: true,
};

export const Login: React.FC = () => {
	const { other } = useMantineTheme();
	const { login } = useAuth();

	const [status, setStatus] = useState(SubmitStatus.THROTTLE);
	const [resultMessage, setResultMessage] = useState('');

	const [searchParams, setSearchParams] = useSearchParams();

	const form = useForm({
		initialValues,
	});

	useEffect(() => {
		const token = searchParams.get('token');
		setSearchParams({});

		if (!token) return;

		(async () => {
			setStatus(SubmitStatus.LOADING);
			const response = await UnauthenticatedApi.verifyEmailToken(token);

			const isError = isApiError(response);

			if (isError) {
				setStatus(SubmitStatus.ERROR);
				setResultMessage(getErrorMessage(response.message));
			} else {
				setStatus(SubmitStatus.SUCCESS);
				setResultMessage('Pomyślnie zweryfikowano adres email');
			}
		})();
	}, []);

	const handleSubmit = async (loginValues: LoginValues) => {
		setStatus(SubmitStatus.LOADING);
		setResultMessage('');

		const result = await login(loginValues);
		form.reset();

		if (result.status === SubmitStatus.ERROR) {
			setStatus(SubmitStatus.ERROR);
			setResultMessage(getErrorMessage(result.stack));
			return;
		}

		setStatus(SubmitStatus.THROTTLE);
	};

	return (
		<Box component="form" onSubmit={form.onSubmit(handleSubmit)}>
			<LoadingOverlay visible={status === SubmitStatus.LOADING} />
			<Logo />
			<Title order={1} c="blue.8" my="lg">
				Zaloguj się
			</Title>
			{status === SubmitStatus.ERROR && (
				<Notification {...other.notificationProps.danger} mb="lg">
					<Text fw={500}>{resultMessage}</Text>
				</Notification>
			)}
			{status === SubmitStatus.SUCCESS && (
				<Notification {...other.notificationProps.success} mb="lg">
					<Text fw={500}>{resultMessage}</Text>
				</Notification>
			)}
			<TextInput
				{...form.getInputProps('email')}
				required
				size="lg"
				type="email"
				mb="lg"
				label="Adres e-mail"
				placeholder="E-mail"
			/>
			<PasswordInput
				{...form.getInputProps('password')}
				size="lg"
				required
				mb="lg"
				label="Hasło"
				placeholder="Hasło"
			/>
			<Checkbox
				{...form.getInputProps('remember', { type: 'checkbox' })}
				label="Zapamiętaj mnie"
				mb="lg"
			/>
			<Button
				themes={['primary', 'fullWidth']}
				buttonProps={{
					type: 'submit',
					mb: 'lg',
				}}
			>
				Zaloguj się
			</Button>
			<Text size="lg">
				Nie pamiętasz hasła?{' '}
				<Anchor
					c="black"
					fw={600}
					component={Link}
					to={`/${AppPaths.RESET_PASSWORD}`}
					underline="hover"
				>
					Kliknij tutaj.
				</Anchor>
			</Text>
		</Box>
	);
};
