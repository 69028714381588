import React from 'react';
import { Flex, Badge, UnstyledButton, Text } from '@mantine/core';
import { Location } from '@/api/LocationApi';
import MarkerIcon from '@/assets/icons/marker-pin-02.svg?react';
import XIcon from '@/assets/icons/x-close.svg?react';
import styles from './LocationPills.module.scss';

interface Props {
	locations: Location[];
	handleRemove: (location: Location) => () => void;
}

export const LocationPills: React.FC<Props> = ({ locations, handleRemove }) => {
	if (!locations.length) return null;
	return (
		<Flex gap={5} align="center" wrap="wrap" className="fade-in">
			<Text size="sm" fw={500}>
				Wybrane lokalizacje
			</Text>
			{locations.map((loc) => (
				<Badge
					key={loc.id}
					leftSection={<MarkerIcon className="svg-danger" />}
					rightSection={
						<UnstyledButton onClick={handleRemove(loc)}>
							<XIcon className="svg-gray-modern-500" />
						</UnstyledButton>
					}
					className={styles.badge}
				>
					<Text fw={500} tt="capitalize">
						{loc.name}
					</Text>
				</Badge>
			))}
		</Flex>
	);
};
