import React, { useMemo } from 'react';
import {
	Box,
	Flex,
	NativeSelect,
	ScrollArea,
	SimpleGrid,
	Text,
	Title,
	Tooltip,
	UnstyledButton,
	Image,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Carousel } from '@mantine/carousel';
import { getFileSrc, mapIndexToPolishText } from '@/utils/utilities';
import { Button } from '@/components/partials/button/Button';
import { useQuery } from '@tanstack/react-query';
import LocationApi, { Location } from '@/api/LocationApi';
import classNames from 'classnames';
import APP_CONFIG from '@/configs/appConfig';
import MarkerIcon from '@/assets/icons/marker-pin-02.svg?react';
import TrashIcon from '@/assets/icons/trash-01.svg?react';
import MapShape from '@/assets/images/polishMap.svg?react';
import MapRivers from '@/assets/images/polishRivers.svg?react';
import styles from './LocationMapPicker.module.scss';

const emptyLocationPills = new Array(APP_CONFIG.MAX_LOCATIONS_LENGTH)
	.fill(null)
	.map((_, i) => (
		<Flex
			key={i}
			py={12}
			px="md"
			gap="sm"
			justify="space-between"
			align="center"
			className={styles.empty}
		>
			<Text fw={500} c="gray.4">
				Wybierz {mapIndexToPolishText(i)} lokalizację na mapie
			</Text>
		</Flex>
	));

interface Props {
	locations: Location[];
	selectedLocation: Location | null;
	handleLocationSelect: (location: Location | null) => void;
	isDisabled: boolean;
	handleAddLocation: (location: Location) => () => void;
	handleRemoveLocation: (location: Location) => () => void;
}

export const LocationMapPicker: React.FC<Props> = ({
	locations,
	handleLocationSelect,
	selectedLocation,
	isDisabled,
	handleAddLocation,
	handleRemoveLocation,
}) => {
	const locationsQuery = useQuery({
		queryKey: [LocationApi.queryKey],
		queryFn: LocationApi.getAll,
		staleTime: Infinity,
	});

	const isMobile = useMediaQuery(`(max-width: ${APP_CONFIG.BREAKPOINTS.XS})`);

	const nativeData = useMemo(
		() =>
			locationsQuery.data
				? [
						...locationsQuery.data.map((location) => ({
							value: location.id,
							label: location.name,
						})),
						{ label: 'Wybierz miasto', value: '', disabled: true },
				  ]
				: [],
		[locationsQuery.data]
	);

	const isSelectedLocationSaved = useMemo(
		() => locations.some((loc) => selectedLocation?.id === loc.id),
		[locations, selectedLocation]
	);

	return (
		<SimpleGrid
			my="xl"
			spacing="xl"
			cols={{
				base: 1,
				md: 2,
			}}
			className={styles.grid}
		>
			{isMobile ? (
				<NativeSelect
					w="100%"
					data={nativeData}
					value={selectedLocation?.id || ''}
					onChange={({ target }) =>
						handleLocationSelect(
							locationsQuery.data?.find((loc) => loc.id === target.value)!
						)
					}
					disabled={isDisabled}
				/>
			) : (
				<Box pos="relative" className={styles.mapContainer}>
					<MapShape className={styles.map} />
					<MapRivers className={styles.rivers} />
					{locationsQuery.data?.map((location) => (
						<Tooltip
							key={location.id}
							label="Wybrano maksymalna liczbę lokalizacji!"
							disabled={!isDisabled}
						>
							<UnstyledButton
								key={location.id}
								className={classNames(styles.marker, {
									[styles.markerActive]:
										selectedLocation?.id === location.id ||
										locations.some((loc) => loc.id === location.id),
								})}
								style={{
									top: `${location.options.top}%`,
									left: `${location.options.left}%`,
								}}
								onClick={() => handleLocationSelect(location)}
								disabled={isDisabled}
							>
								<Box className={styles.markerIcon}>
									<Box className={styles.markerIconInner}>
										<Box className={styles.markerIconCenter}></Box>
									</Box>
								</Box>
								<Text size="sm" fw={500} {...location.options.textProps}>
									{location.name}
								</Text>
							</UnstyledButton>
						</Tooltip>
					))}
				</Box>
			)}
			{!selectedLocation && !locations.length && (
				<Box p="lg" className={styles.box} ta="center">
					<Title order={3} mb="md">
						Nie wybrano lokalizacji
					</Title>
					<Text c="dark.6" fw={500}>
						Proszę wybrać lokalizacje na mapie maksymalnie można zaznaczyć 6
						miejsc
					</Text>
				</Box>
			)}
			{!selectedLocation && !!locations.length && (
				<Box p="lg" className={styles.box}>
					<Title order={2} mb={12}>
						Lokalizacje
					</Title>
					<Flex direction="column" gap={12}>
						{locations.map((loc) => (
							<Flex
								key={loc.id}
								py={12}
								pl="lg"
								pr="md"
								gap="sm"
								justify="space-between"
								align="center"
								className={styles.location}
							>
								<Text fw={500}>{loc.name}</Text>
								<UnstyledButton
									w={24}
									h={24}
									onClick={handleRemoveLocation(loc)}
								>
									<TrashIcon className="svg-gray-modern-500" />
								</UnstyledButton>
							</Flex>
						))}
						{emptyLocationPills.toSpliced(0, locations.length)}
					</Flex>
				</Box>
			)}
			{!!selectedLocation && (
				<Box className={styles.cityPreviewBox}>
					<Carousel height={186}>
						{selectedLocation.gallery.map((attach) => (
							<Carousel.Slide key={attach.id}>
								<Image src={getFileSrc(attach.path)} mah="100%" fit="cover" />
							</Carousel.Slide>
						))}
					</Carousel>
					<Box
						className={styles.cityPreviewContent}
						p={{ base: 'md', md: 'lg' }}
					>
						<Title order={2} c="blue.7" mb="md">
							{selectedLocation.name}
						</Title>
						<Title order={4} mb="sm">
							Przykładowe miejsca startu
						</Title>
						<Flex mb={20} gap="sm">
							{selectedLocation.startingPoints.map((point) => (
								<Flex gap="sm" align="center" key={point.id}>
									<MarkerIcon className="svg-danger" width={16} height={16} />
									<Text size="md" fw={500}>
										{point.name}
									</Text>
								</Flex>
							))}
						</Flex>
						<ScrollArea.Autosize mah={80} mb={20}>
							<Text c="dark.6" fw={500}>
								{selectedLocation.description}
							</Text>
						</ScrollArea.Autosize>
						<Button
							themes={['primary', 'fullWidth']}
							buttonProps={{
								type: 'button',
								onClick: isSelectedLocationSaved
									? handleRemoveLocation(selectedLocation)
									: handleAddLocation(selectedLocation),
							}}
						>
							{isSelectedLocationSaved
								? 'Usuń lokalizację'
								: 'Wybierz lokalizację'}
						</Button>
					</Box>
				</Box>
			)}
		</SimpleGrid>
	);
};
