import { QueryKey } from '@/configs/queryKeys';
import { UUID } from '@/types/types';
import { OrderForm } from '@/components/views/authenticated/cartCheckout/CartCheckout';
import { AppPaths } from '@/configs/appPaths';
import { User } from '@/types/user';
import { Payment } from './PaymentApi';
import { PaymentMethod } from '@/configs/paymentOptions';
import { CartPrice } from './CartApi';
import { ShippingMethod } from './ShippingMethodApi';
import httpClient from './httpClient';
import EntityApi from './EntityApi';

export enum OrderStatus {
	PENDING,
	ACCEPTED,
	REJECTED,
	CONFIRMED_ADVANCE,
}

export interface OrderItem {
	id: UUID;
	createdAt: string;
	name: string;
	price: CartPrice;
	quantity: number;
	productId: UUID | null;
}

export interface Order {
	id: UUID;
	orderId: string;
	orderItems: OrderItem[];
	user: User;
	payments: Payment[];
	paymentMethod: PaymentMethod;
	shippingMethod: ShippingMethod;
	status: OrderStatus;
	price: CartPrice;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	city: string;
	address: string;
	postalCode: string;
	createdAt: string;
}

export type CreateOrderResponse = Order & {
	paymentUrl: string;
	externalId: string;
};

class OrderApi extends EntityApi<Order> {
	constructor() {
		super(QueryKey.order);
		this.createOrder = this.createOrder.bind(this);
		this.repeatPayment = this.repeatPayment.bind(this);
	}

	async createOrder(orderForm: OrderForm) {
		const response = await httpClient.post<CreateOrderResponse>(this.queryKey, {
			...orderForm,
			isAdvance: false,
			shippingMethod: { id: orderForm.shippingMethod },
			returnUrl: `${import.meta.env.VITE_APP_URL}${
				AppPaths.PAYMENT_RESULT
			}/:id`,
		});

		return response.data;
	}

	async repeatPayment(orderId: UUID) {
		const response = await httpClient.patch<CreateOrderResponse>(
			`${this.queryKey}/${orderId}/pay`,
			{
				returnUrl: `${import.meta.env.VITE_APP_URL}${
					AppPaths.PAYMENT_RESULT
				}/:id`,
			}
		);
		return response.data;
	}
}

export default new OrderApi();
