import React, { lazy, useLayoutEffect } from 'react';
import { UnauthenticatedApp } from './UnauthenticatedApp';
import { useAuth } from '@/providers/AuthProvider';
import { useLocation } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { ErrorPage } from '@/components/views/authenticated/errorPage/ErrorPage';
import { Loading } from '@/components/partials/loading/Loading';
import { setErrorCode } from '@/store/slices/appSlice';
import httpClient from '@/api/httpClient';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));

export const App: React.FC = () => {
	const dispatch = useTypedDispatch();

	const { pathname } = useLocation();

	const { user } = useAuth();

	useLayoutEffect(() => {
		dispatch(setErrorCode(''));
	}, [pathname]);

	const { errorCode, loading } = useTypedSelector((state) => state.app);

	if (user && !httpClient.defaults.headers.common.Authorization)
		return <Loading />;

	return getAppContent(loading, errorCode, !!user);
};

const getAppContent = (
	loading: boolean,
	errorCode: string,
	isUser: boolean
) => {
	if (loading) return <Loading />;
	if (errorCode) return <ErrorPage httpCode={errorCode} />;
	if (isUser) return <AuthenticatedApp />;
	return <UnauthenticatedApp />;
};
